import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
} from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  BrowserModule,
  // provideClientHydration,
  // withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  ChaincargoModule,
  provideCookieInterceptor,
  providePreviewBuildInterceptor,
  provideBypassServiceWorkerInterceptor,
  provideNotFoundInterceptor,
  providePublicLanguageInterceptor,
} from '@cca-infra/core';
import { AuthenticationModule } from '@cca-common/authentication';
import {
  CommonDataModule,
  LoggingDomainModule,
  BUILD_INFO,
} from '@cca-common/core';
import {
  PlatformEnvironmentToken,
  WINDOW,
  rootUrlToken,
} from '@cca-common/environment';
import { NavigationServiceToken } from '@cca-common/header';
import { CommonNotificationsModule } from '@cca-common/notifications';
import { PageTitleStrategy } from '@cca-common/page-title';
import {
  TranslocoHttpBackendToken,
  TranslocoRootModule,
} from '@cca-common/translations';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { isNode } from 'browser-or-node';
import { environment } from '../environments/environment';
import { buildInformation } from '../version';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './store';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './auth-config';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
} from '@angular/material/paginator';
import { TranslationService } from '@cca-infra/translation-management/v1';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      // angular modules
      BrowserModule,
      // translations
      TranslocoRootModule,
      // store ngrx
      AppStoreModule,

      AppRoutingModule,

      // api
      ChaincargoModule.forRoot(environment.api),
      // Sso
      MsalModule,
      // domains
      // common data should be first else actions to load common data won't trigger a load of the requested data.
      CommonDataModule.forRoot(),
      AuthenticationModule.forRoot(environment.features.authentication),
      LoggingDomainModule.forRoot(environment.features.logger),
      // service worker
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isNode && environment.serviceWorker,
      }),
      CommonNotificationsModule,

      // MsalModule
    ),
    {
      provide: PlatformEnvironmentToken,
      useValue: environment,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        appearance: 'outline',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
      } as MatPaginatorDefaultOptions,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true,
      } as MatDialogConfig,
    },
    provideNotFoundInterceptor(),
    provideBypassServiceWorkerInterceptor(),
    provideCookieInterceptor(),
    providePreviewBuildInterceptor(),
    providePublicLanguageInterceptor(),
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'web',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [NavigationServiceToken],
      multi: true,
    },
    {
      provide: BUILD_INFO,
      useValue: buildInformation,
    },
    // provide PageTitleStrategy
    PageTitleStrategy,
    {
      provide: TitleStrategy,
      useExisting: PageTitleStrategy,
    },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    // provideClientHydration(withNoHttpTransferCache()),
    {
      provide: rootUrlToken,
      useFactory: () => {
        const window = inject(WINDOW);
        return `${window?.location?.origin}/`;
      },
    },

    // Single Sign on providers
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    // provide a backend service to get translations from
    {
      provide: TranslocoHttpBackendToken,
      useClass: TranslationService,
    },
  ],
};
